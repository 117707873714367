import Spinner from 'spin.js';


const spinner = document.createElement('div');
spinner.id = 'spinner';
spinner.style.cssText =
  'top:0;left:0;right:0;bottom:0;z-index:-1;position:absolute;';
document.body.appendChild(spinner);

window._spinner = new Spinner({
  lines: 12,
  width: 1,
  scale: 0.75,
  rotate: 90,
  length: 5,
  radius: 6,
  corners: 1,
  opacity: 0.3
}).spin(spinner);


const loadFirebase = (uri) => {
  const sdkScript = document.createElement('script');
  sdkScript.async = true;
  sdkScript.src = uri;

  const script = document.getElementsByTagName('script')[0];
  script.parentNode.insertBefore(sdkScript, script);
};
loadFirebase(
  'https://www.gstatic.com/firebasejs/7.13.2/firebase-performance-standalone.js'
);

window.addEventListener('load', function () {
  firebase
    .initializeApp({
      apiKey: 'AIzaSyCQmEfMcQfX63NcI3WLAY6w6hSC3P9dVtA',
      authDomain: 'bc-coronavirus.firebaseapp.com',
      databaseURL: 'https://bc-coronavirus.firebaseio.com',
      projectId: 'bc-coronavirus',
      storageBucket: 'bc-coronavirus.appspot.com',
      messagingSenderId: '929338890372',
      appId: '1:929338890372:web:0eb7f0816d417e87420ad1',
      measurementId: 'G-MPMG3D7JLH'
    })
    .performance();
});


